import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Switch from "@mui/material/Switch";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UserContext } from "../../../App";
import storage from "../../../Utils/storage";
import AccountSettingPic1 from "../../../assets/MyAccount/AccountSettingPic1.png";
import AccountSettingPic2 from "../../../assets/MyAccount/AccountSettingPic2.png";
import accountUploadBgpic from "../../../assets/MyAccount/accountUploadBgpic.png";
import { CheckNumberRequest } from "../../../redux/Action/CheckEmail/CheckNumber";
import { otpMobileRequest } from "../../../redux/Action/OTP/OTPMobile/otpMobileAction";
import { generateEmailOtpRequest } from "../../../redux/Action/OTP/generateEmailOtpAction";
import { GetProfileRequest } from "../../../redux/Action/User/GetProfileAction";
import { UpdateProfileRequest } from "../../../redux/Action/User/UpdateProfile";
import { UploadFamilyPicRequest } from "../../../redux/Action/User/UploadFamilyPicture";
import { UploadProfilePicRequest } from "../../../redux/Action/User/UploadProfilePicture";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import UpdateProfileEmailOTPScreen from "../../Organisms/OTP/UpdateProfileOTPScreen/UpdateProfileEmailOTPModal";
import UpdateProfileNumberOTPScreen from "../../Organisms/OTP/UpdateProfileOTPScreen/UpdateProfileNumberOTPModal";
import "./AccountSetting.css";

function AccountSetting() {
  const role = useContext(UserContext);

  const loginSchema = Yup.object().shape({
    Gender: Yup.string().nullable().required("Required Field"),
    DateOfBirth: Yup.string().required("Required Field"),
    Address: Yup.string().nullable().required("Required Field"),
    Pincode: Yup.string().nullable().required("Required Field"),
    City: Yup.string().nullable().required("Required Field"),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [input, setInput] = useState({});
  const [valEmail, setVal] = useState(null);
  const [valNumber, setValNumber] = useState(null);
  const [NumberCode, setCode] = useState();
  const [emailCode, setEmailCode] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const [showNumberOTP, setShowNumberOTP] = useState(false);
  const [emailCheck, setEmailCheck] = useState();
  const [numberCheck, setNumberCheck] = useState();
  const [valNumberVerify, setValNumberVerify] = useState(null);
  const [valEmailVerify, setValEmailVerify] = useState(null);
  const [verifyErrorCheck, setVerifyErrorCheck] = useState(false);
  const [isNumberFieldChanged, setNumberFieldChanged] = useState(false);
  const [isEmailFieldChanged, setEmailFieldChanged] = useState(false);

  const [mobilePhoneValidation, setMobilePhoneValidation] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [alreadyExistEmail, setAlreadyExistEmail] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [alreadyExistNumber, setAlreadyExistNumber] = useState(false);
  const [checkingNumber, setCheckingNumber] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const UpdateProfileOtpValidation = useSelector(
    (state) => state.otp.UpdateProfileOtpValidation
  );

  const isDigit = (value) => {
    return /^\d+$/.test(value);
  };

  const viewCityNames = useSelector((state) => state.city.viewAllCity);

  useEffect(() => {
    dispatch(GetProfileRequest());
  }, [dispatch]);

  useEffect(() => {
    if (valEmail) {
      setEmailCheck(false);
    }
  }, [valEmail]);

  useEffect(() => {
    if (valNumber) {
      setNumberCheck(false);
    }
  }, [valNumber]);
  const GetProfile = useSelector((state) => state.user.getProfile);
  const profileLock = useSelector((state) => state.profileLock);
  const uploadProfilePicture = useSelector(
    (state) => state.user.uploadProfilePicture
  );

  const uploadFamilyPic = useSelector((state) => state.user.uploadFamilyPic);
  const AccountDetails = GetProfile?.data?.data?.data;

  const generateEmailOtp = useSelector((state) => state.otp.generateEmailOtp);

  const formRef = useRef();
  const [coverImage, setCoverImage] = useState(null);
  const [coverImage1, setCoverImage1] = useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [profileImage1, setProfileImage1] = useState(null);
  const [profileImagecheck, setProfileImagecheck] = useState(false);
  const [profileFamilyImagecheck, setProfileFamilyImagecheck] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editProfilePic, setEditProfilePic] = useState(false);

  const updateProfile = useSelector((state) => state.user.updateProfile);
  const checkNumberSelector = useSelector(
    (state) => state.checkEmail.checkNumber
  );

  const [check, setCheck] = useState(false);
  function handleEdit() {
    setEdit(!edit);
  }
  if (profileLock?.data) {
    Swal.fire({
      icon: "success",
      text: profileLock?.data?.data?.data?.message,
    });
  }
  const otpMobileData = useSelector((state) => state.otp.otpMobile);
  useEffect(() => {
    if (otpMobileData?.data?.data.data.status === true) {
      Swal.fire({
        title: " OTP Is Sent To Your Mobile Number Successfully",
        icon: "success",
      }).then(() => {
        setShowNumberOTP(true);
      });
    }
  }, [otpMobileData?.data?.data.data.status]);

  useEffect(() => {
    if (generateEmailOtp?.data?.data.data.status === true) {
      Swal.fire({
        title: " OTP Is Sent To Your E-mail Id successfully",
        icon: "success",
      }).then(() => {
        setShowOTP(true);
      });
    }
  }, [generateEmailOtp?.data?.data.data.status]);

  useEffect(() => {
    if (
      updateProfile?.error?.data.response.data !== null &&
      updateProfile?.error?.data.response.status === 409
    ) {
      Swal.fire({
        title: updateProfile?.error?.data.response.data.errorMessage,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [updateProfile?.error]);

  const saveInput = (values) => {
    if (profileImagecheck) {
      dispatch(UploadProfilePicRequest(formData2));
    }

    if (profileFamilyImagecheck) {
      dispatch(UploadFamilyPicRequest(formData1));
    }

    if (
      (valEmail === "" && emailCheck === false) ||
      (valNumber === "" && numberCheck === false)
    ) {
      setVerifyErrorCheck(false);
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }
    if (valEmail !== null && emailCheck === false) {
      setVerifyErrorCheck(true);
    } else if (valEmail === null && emailCheck === false) {
      setVerifyErrorCheck(false);
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }
    if (valNumber !== null && numberCheck === false) {
      setVerifyErrorCheck(true);
    } else if (valNumber === null && numberCheck === false) {
      setVerifyErrorCheck(false);
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }
    if (valEmail === null && valNumber === null) {
      setVerifyErrorCheck(false);
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }

    if (
      (valEmail !== null && emailCheck === true) ||
      (valNumber !== null && numberCheck === true)
    ) {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    } else if (
      (valEmail !== null && emailCheck === false) ||
      (valNumber !== null && numberCheck === false)
    ) {
      setVerifyErrorCheck(true);
    }
  };
  useEffect(() => {
    if (
      updateProfile.data !== null &&
      updateProfile.data?.data.data.data.jwt !== null
    ) {
      storage.removeStorage("token");
      storage.setStorage("token", updateProfile?.data?.data?.data.data.jwt);
    }
  }, [updateProfile.data]);

  if (
    AccountDetails?.data.dob !== null &&
    AccountDetails?.data.gender !== null
  ) {
    storage.setStorage("profileUpdated", true);
  } else {
    storage.setStorage("profileUpdated", false);
  }
  const formData1 = new FormData();
  const formData2 = new FormData();
  formData1.append("familyPic", coverImage);

  formData2.append("profilePic ", profileImage);
  useEffect(() => {
    if (profileImage !== null) {
      setProfileImagecheck(true);
    }
    if (coverImage !== null) {
      setProfileFamilyImagecheck(true);
    }
  }, [profileImage, coverImage]);

  useEffect(() => {
    if (input?.name) {
      if (isDigit(input.City)) {
        dispatch(
          UpdateProfileRequest(
            input,
            input.City,
            NumberCode,
            emailCode,
            valEmail === AccountDetails.data.email ? null : valEmail,
            valNumber === AccountDetails.data.number ? null : valNumber
          )
        );
      } else {
        dispatch(
          UpdateProfileRequest(
            input,
            AccountDetails.data.cityId,
            NumberCode,
            emailCode,
            valEmail === AccountDetails.data.email ? null : valEmail,
            valNumber === AccountDetails.data.number ? null : valNumber
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (AccountDetails?.data?.email !== null) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
  }, [AccountDetails]);

  useEffect(() => {
    if (AccountDetails?.data?.number !== null) {
      setNumberCheck(true);
    } else {
      setNumberCheck(false);
    }
  }, [AccountDetails]);

  useEffect(() => {
    if (
      UpdateProfileOtpValidation?.data?.data?.data?.data === true &&
      emailCheck === false &&
      valEmailVerify !== null
    ) {
      setEmailCheck(true);
      setValEmailVerify(null);
    } else if (
      UpdateProfileOtpValidation?.data?.data?.data?.data === true &&
      numberCheck === false &&
      valNumberVerify !== null
    ) {
      setNumberCheck(true);
      setValNumberVerify(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UpdateProfileOtpValidation.data?.data?.data?.data]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCoverImage(URL.createObjectURL(event.target.files[0]));
      setCheck(true);
    }
  };
  const handleProfilePic = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
      setEditProfilePic(true);
      setProfileImage1(URL.createObjectURL(event.target.files[0]));
    }
  };

  if (AccountDetails?.data?.dob !== null) {
    const epochValue = AccountDetails?.data?.dob;

    const date = new Date(epochValue);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    var dateee = `${year}-${month}-${day}`;
  }

  localStorage.setItem("Date Of Birth", dateee);
  const handleCheckFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCoverImage(event.target.files[0]);
      setCoverImage1(URL.createObjectURL(event.target.files[0]));

      setCheck(true);
    }
  };

  const handleProfilePicCheck = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(event.target.files[0]);
      setProfileImage1(URL.createObjectURL(event.target.files[0]));
      setEditProfilePic(true);
    }
  };

  useEffect(() => {
    if (valNumber === "") {
      setMobilePhoneValidation(false);
      setNumberFieldChanged(false);
      setNumberCheck(false);
      setAlreadyExistNumber(false);
    }
  }, [valNumber, isNumberFieldChanged, numberCheck, alreadyExistNumber]);

  useEffect(() => {
    if (valEmail === "") {
      setEmailFieldChanged(false);
      setAlreadyExistEmail(false);
      setEmailCheck(false);
      setIsEmailValid(true);
    }
  }, [valEmail, isEmailFieldChanged, alreadyExistEmail, isEmailValid]);

  useEffect(() => {
    if (
      checkNumberSelector?.error?.data?.response.data.data === true &&
      checkingEmail === true
    ) {
      setEmailCheck(true);
      setAlreadyExistEmail(true);
      setCheckingEmail(false);
    } else if (
      checkNumberSelector?.error?.data?.response.data.data === false &&
      checkingEmail === true
    ) {
      setAlreadyExistEmail(false);
      setCheckingEmail(false);
    }

    if (
      checkNumberSelector?.error?.data?.response.data.data === true &&
      checkingNumber === true
    ) {
      setNumberCheck(true);
      setAlreadyExistNumber(true);
      setCheckingNumber(false);
    } else if (
      checkNumberSelector?.error?.data?.response.data.data === false &&
      checkingNumber === true
    ) {
      setAlreadyExistNumber(false);
      setCheckingNumber(false);
    }
  }, [
    checkNumberSelector?.error?.data?.response.data.data,
    alreadyExistEmail,
    alreadyExistNumber,
  ]);

  useEffect(() => {
    if (verifyErrorCheck) {
      Swal.fire({
        title: "Please Do Complete Your Verification Process ",
        icon: "error",
      }).then(() => {
        setVerifyErrorCheck(false);
      });
    }
  }, [verifyErrorCheck]);

  useEffect(() => {
    if (valNumber?.length === 10) {
      setMobilePhoneValidation(false);
    }
  }, [valNumber]);

  useEffect(() => {
    if (profileImagecheck) {
      if (uploadProfilePicture?.data?.data.status === 200) {
        Swal.fire({
          title: uploadProfilePicture?.data?.data?.data?.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      }
      if (uploadProfilePicture?.error?.data?.response?.status === 417) {
        Swal.fire({
          title:
            uploadProfilePicture?.error?.data?.response?.data?.errorMessage,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
      }
    } else if (profileFamilyImagecheck) {
      if (uploadFamilyPic?.data?.data.status === 200) {
        Swal.fire({
          title: uploadFamilyPic?.data?.data?.data?.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      }
    } else if (profileFamilyImagecheck && profileImagecheck) {
      if (
        updateProfile?.data?.data?.status === 200 &&
        uploadFamilyPic?.data?.data.status === 200 &&
        uploadProfilePicture?.data?.data.status === 200
      ) {
        Swal.fire({
          title: updateProfile?.data?.data?.data?.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      }
    } else if (updateProfile?.data?.data?.status === 200) {
      Swal.fire({
        title: updateProfile?.data?.data?.data?.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [
    updateProfile,
    uploadFamilyPic,
    uploadProfilePicture,
    profileImagecheck,
    profileFamilyImagecheck,
  ]);

  if (storage.getStorage("token") === null) {
    navigate("/");
  }
  if (uploadFamilyPic.isLoading || uploadProfilePicture.isLoading) {
    return <YuvaLoader />;
  }

  function getNumberVerificationCode(value) {
    setCode(value);
  }
  function getEmailVerificationCode(value) {
    setEmailCode(value);
  }

  function closeOtpBoxNumber() {
    setShowNumberOTP(false);
  }
  function closeOtpBox() {
    setShowOTP(false);
  }

  function handleEmailVerification() {
    dispatch(generateEmailOtpRequest(valEmail, AccountDetails?.data?.name));
  }
  function handleNumberVerification() {
    dispatch(otpMobileRequest(valNumber));
  }

  function handleChangeEmail(e) {
    clearTimeout(debounceTimeoutRef.current);
    if (e.target.value) {
      setCheckingEmail(true);
      dispatch(CheckNumberRequest(e.target.value));
    }
    const email = e.target.value;
    setVal(e.target.value);
    setValEmailVerify(e.target.value);
    setEmailFieldChanged(true);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);
    setIsEmailValid(isValid);
  }

  function handleChangeNumber(e) {
    clearTimeout(debounceTimeoutRef.current);
    if (e.target.value) {
      dispatch(CheckNumberRequest(e.target.value));
      setCheckingNumber(true);
    }
    const PhoneNumber = e.target.value;
    setValNumber(e.target.value);
    setValNumberVerify(e.target.value);
    setNumberFieldChanged(true);
    setMobilePhoneValidation(
      PhoneNumber.length < 10 || PhoneNumber.length > 10
    );
  }
  return (
    <>
      <Title title="Yuva | My Account Settings"></Title>

      {showOTP && (
        <UpdateProfileEmailOTPScreen
          email={valEmail}
          OTPEmailCode={getEmailVerificationCode}
          closeOtpBox={closeOtpBox}
          setShow={setShowOTP}
          name={AccountDetails?.data?.name}
        />
      )}
      {showNumberOTP && (
        <UpdateProfileNumberOTPScreen
          number={valNumber}
          OtpCode={getNumberVerificationCode}
          closeOtpBoxNumber={closeOtpBoxNumber}
          setShowNumber={setShowNumberOTP}
        />
      )}
      <div className={(showOTP || showNumberOTP) && "registerWithOtp"}>
        <div className="accountSettingHeading">Your Information</div>
        <div className="accountSettingBody">
          {}
          <div
            style={{
              backgroundImage:
                AccountDetails?.data?.familyPicture !== null
                  ? check
                    ? `url(${coverImage1})`
                    : `url(${AccountDetails?.data?.familyPicture})`
                  : check
                  ? `url(${coverImage1})`
                  : `url(${AccountSettingPic1})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            className="accountSettingSubBody"
          >
            <div
              style={{
                backgroundColor: edit
                  ? "rgba(47, 47, 47, 0.67)"
                  : "rgba(0, 0, 0, 0)",
                borderRadius: "16px",
              }}
              className="accountSettingUploadMask"
            >
              {edit ? (
                <div className="accountSettingUpload">
                  <label for="img1">
                    {" "}
                    <img
                      src={accountUploadBgpic}
                      alt="uploadSign"
                      className="uploadSignMain"
                    ></img>
                  </label>

                  <div>
                    <div className="faccountFileUploadContainer">
                      <input
                        type="file"
                        name="UploadApproval"
                        style={{ display: "none" }}
                        id="img1"
                        ondrop={handleDragOver}
                        ondragover={handleDrop}
                        accept="image/*"
                        onBlur={(event) => handleFileChange(event)}
                        onChange={(event) => handleCheckFile(event)}
                      />
                    </div>
                  </div>

                  {coverImage?.size / 1024 > 10240 && (
                    <div className="accountErrorDisableModalValidation1">
                      {" "}
                      File Size should be less than 10mb
                    </div>
                  )}

                  <div className="accountSelectFileMessage">
                    {" "}
                    <label for="img1"> Upload Cover Photo</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {edit ? (
                <button
                  style={{ marginTop: "0vw" }}
                  className="accountSettingEditButton"
                  onClick={() => saveInput()}
                >
                  <SaveIcon />
                  Save
                </button>
              ) : (
                <button
                  className="accountSettingEditButton"
                  onClick={handleEdit}
                >
                  <EditIcon />
                  Edit Profile
                </button>
              )}
            </div>
          </div>
          <div className="accountpicBody">
            <div
              style={{
                backgroundImage:
                  AccountDetails?.data?.profilePicture !== null
                    ? editProfilePic
                      ? `url(${profileImage1})`
                      : `url(${AccountDetails?.data?.profilePicture})`
                    : editProfilePic
                    ? `url(${profileImage1})`
                    : `url(${AccountSettingPic2})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
              className="accountSettingProfilePic"
            >
              <div
                style={{
                  backgroundColor: edit
                    ? "rgba(255, 255,255, 0.67)"
                    : "rgba(0, 0, 0, 0)",
                  height: "5em",
                  borderRadius: "50%",
                }}
              >
                {edit ? (
                  <div className="accountSettingUpload">
                    <div>
                      <div className="faccountFileUploadContainer">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          name="UploadApproval"
                          id="img"
                          ondrop={handleDragOver}
                          ondragover={handleDrop}
                          accept="image/*"
                          onBlur={(event) => handleProfilePic(event)}
                          onChange={(event) => handleProfilePicCheck(event)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {edit ? (
                  <label for="img">
                    <EditIcon className="editIcon2" />
                  </label>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <Formik
            id="idd"
            innerRef={formRef}
            initialValues={{
              name: AccountDetails?.data?.name,
              Company: AccountDetails?.data?.companyName,
              Email: AccountDetails?.data?.email,
              Address: AccountDetails?.data?.address,
              MobileNumber: AccountDetails?.data?.number,
              Gender: AccountDetails?.data?.gender,
              City: AccountDetails?.data?.cityName,
              DateOfBirth: dateee,
              Pincode: AccountDetails?.data?.pinCode,
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={(values) => setInput(values)}
          >
            {({ values, setFieldValue, touched }) => {
              return (
                <>
                  <div className="inputFieldGrid">
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Name</div>{" "}
                      <label>
                        <Field
                          placeholder=" Name"
                          disabled={true}
                          name="name"
                          type="text"
                          className="inputFieldTextBody"
                        ></Field>
                        <ErrorMessage
                          className="inputFieldTextBody"
                          name="name"
                          component="div"
                        />
                      </label>
                    </div>
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Company</div>{" "}
                      <label>
                        <Field
                          placeholder=" Company"
                          name="Company"
                          disabled={true}
                          type="text"
                          className="inputFieldTextBody"
                        ></Field>
                        <ErrorMessage
                          className="inputFieldTextBody"
                          name="Company"
                          component="div"
                        />
                      </label>
                    </div>{" "}
                    {edit ? (
                      <>
                        {role === "EMPLOYEE" ? (
                          <>
                            <div className="inputFieldBody">
                              <div className="inputFieldText">Email</div>{" "}
                              <label>
                                <Field
                                  id="emailInput"
                                  placeholder=" Email"
                                  name="Email"
                                  type="text"
                                  disabled={true}
                                  className="inputFieldTextBody"
                                ></Field>
                                <ErrorMessage
                                  className="inputFieldTextBody"
                                  name="Email"
                                  component="div"
                                />
                              </label>
                            </div>{" "}
                          </>
                        ) : (
                          <>
                            <div className="inputFieldBody">
                              <div className="inputFieldText">Email</div>{" "}
                              <label>
                                <Field
                                  id="emailInput"
                                  placeholder=" Email"
                                  name="Email"
                                  type="text"
                                  value={valEmail}
                                  className="inputFieldTextBody"
                                  onChange={(e) => {
                                    handleChangeEmail(e);
                                  }}
                                ></Field>
                                {!isEmailValid && (
                                  <div className="ProfileEmailValidationInput">
                                    Invalid email address
                                  </div>
                                )}
                                <div>
                                  {checkNumberSelector?.error?.data?.response
                                    .status === 302 && alreadyExistEmail ? (
                                    <div className="ProfileEmailValidationInput">
                                      Email already exist!{" "}
                                    </div>
                                  ) : (
                                    <div> </div>
                                  )}
                                </div>
                                {isEmailValid && !alreadyExistEmail ? (
                                  emailCheck &&
                                  UpdateProfileOtpValidation?.data?.data.data
                                    .data === true ? (
                                    <>
                                      <div
                                        disabled={!valEmail}
                                        className="UpdateProfileVerifyButtonGreen"
                                      >
                                        Verified
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {valEmail ===
                                        AccountDetails?.data?.email ||
                                      checkNumberSelector?.error?.data?.response
                                        .status === 302 ||
                                      valEmail === null ? (
                                        <></>
                                      ) : (
                                        <>
                                          {isEmailFieldChanged ? (
                                            <button
                                              onClick={handleEmailVerification}
                                              disabled={!valEmail}
                                              className="UpdateProfileVerifyButton"
                                            >
                                              Verify
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )
                                ) : (
                                  <></>
                                )}
                              </label>
                            </div>{" "}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="inputFieldBody">
                          <div className="inputFieldText">Email</div>{" "}
                          <label>
                            <Field
                              placeholder=" Email"
                              name="Email"
                              type="text"
                              disabled={true}
                              className="inputFieldTextBody"
                            ></Field>
                            <ErrorMessage
                              className="inputFieldTextBody"
                              name="Email"
                              component="div"
                            />
                          </label>
                        </div>{" "}
                      </>
                    )}
                    {edit ? (
                      <>
                        <div className="inputFieldBody">
                          <div className="inputFieldText">Mobile Number</div>{" "}
                          <label>
                            <Field
                              placeholder=" Mobile Number"
                              name="MobileNumber"
                              type="number"
                              className="inputFieldTextBody"
                              onChange={(e) => {
                                setFieldValue("MobileNumber", e.target.value);
                                handleChangeNumber(e);
                              }}
                            ></Field>
                            {mobilePhoneValidation && (
                              <div className="NumberCheckValidationProfile">
                                Number should be 10-digit
                              </div>
                            )}
                            {checkNumberSelector?.error?.data?.response
                              .status === 302 && alreadyExistNumber ? (
                              <div className="NumberCheckValidationProfile">
                                Number already exist!{" "}
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {!mobilePhoneValidation && !alreadyExistNumber ? (
                              numberCheck &&
                              UpdateProfileOtpValidation?.data?.data.data
                                .data === true ? (
                                <>
                                  <div className="UpdateProfileVerifyButtonGreen">
                                    Verified
                                  </div>
                                </>
                              ) : (
                                <>
                                  {valNumber === AccountDetails?.data?.number ||
                                  checkNumberSelector?.error?.data?.response
                                    .status === 302 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {isNumberFieldChanged ? (
                                        <button
                                          onClick={handleNumberVerification}
                                          className="UpdateProfileVerifyButton"
                                        >
                                          Verify
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )
                            ) : (
                              <></>
                            )}
                          </label>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        <div className="inputFieldBody">
                          <div className="inputFieldText">Mobile Number</div>{" "}
                          <label>
                            <Field
                              placeholder=" Mobile Number"
                              name="MobileNumber"
                              type="number"
                              disabled={true}
                              className="inputFieldTextBody"
                            ></Field>
                            <ErrorMessage
                              className="inputFieldTextBody"
                              name="MobileNumber"
                              component="div"
                            />
                          </label>
                        </div>{" "}
                      </>
                    )}
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Gender</div>{" "}
                      <label>
                        {edit ? (
                          <>
                            {" "}
                            <Field
                              className="inputFieldTextBody1"
                              as="select"
                              name="Gender"
                              label="Select Type"
                            >
                              <option value="">Select Gender</option>
                              <option value="Male" label="Male">
                                Male
                              </option>
                              <option value="Female" label="Female">
                                Female
                              </option>
                            </Field>
                            <ErrorMessage
                              className="profileUpdatevalidationDob"
                              name="Gender"
                              component="div"
                            />
                          </>
                        ) : (
                          <>
                            <Field
                              placeholder="Select Gender"
                              name="Gender"
                              type="text"
                              disabled={!edit}
                              className="inputFieldTextBody"
                            ></Field>
                            <ErrorMessage
                              className="inputFieldTextBody"
                              name="Gender"
                              component="div"
                            />
                          </>
                        )}
                      </label>
                    </div>{" "}
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Date of Birth</div>{" "}
                      <label>
                        <Field
                          placeholder=" Date of Birth"
                          name="DateOfBirth"
                          type="date"
                          disabled={!edit}
                          className="inputFieldTextBodyDate"
                        ></Field>
                        <ErrorMessage
                          className="profileUpdatevalidationDob"
                          name="DateOfBirth"
                          component="div"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="inputFieldGrid">
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Address</div>{" "}
                      <label>
                        <Field
                          placeholder="Address"
                          name="Address"
                          type="text"
                          as="textarea"
                          rows={4}
                          style={{ resize: "vertical" }}
                          disabled={!edit}
                          className="inputFieldTextAreaBody"
                        ></Field>
                        <ErrorMessage
                          className="profileUpdatevalidationAdd"
                          name="Address"
                          component="div"
                        />
                      </label>
                    </div>{" "}
                  </div>
                  <div className="inputFieldGrid">
                    <div className="inputFieldBody">
                      <div className="inputFieldText">City</div>{" "}
                      <label>
                        {edit ? (
                          <>
                            {" "}
                            <Field
                              className="inputFieldTextBodyCity"
                              as="Select"
                              label="Select Type"
                              name="City"
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              {viewCityNames?.data?.data?.data?.data.map(
                                (item, index) => (
                                  <option
                                    key={index}
                                    value={item.id}
                                    selected={
                                      item.name ===
                                      AccountDetails?.data?.cityName
                                    }
                                  >
                                    {item.name}
                                  </option>
                                )
                              )}
                            </Field>
                            <ErrorMessage
                              className="profileUpdatevalidationDob"
                              name="City"
                              component="div"
                            />{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <Field
                              placeholder=" City"
                              name="City"
                              disabled={!edit}
                              type="text"
                              className="inputFieldTextBody"
                            ></Field>
                            <ErrorMessage
                              className="inputFieldTextBody"
                              name="City"
                              component="div"
                            />{" "}
                          </>
                        )}
                      </label>
                    </div>{" "}
                    <div className="inputFieldBody">
                      <div className="inputFieldText">Pincode</div>{" "}
                      <label>
                        <Field
                          placeholder=" Pincode"
                          name="Pincode"
                          disabled={!edit}
                          type="number"
                          className="inputFieldTextBody"
                        ></Field>
                        <ErrorMessage
                          className="profileUpdatevalidationDob"
                          name="Pincode"
                          component="div"
                        />
                      </label>
                    </div>{" "}
                  </div>
                </>
              );
            }}
          </Formik>
        </div>

        <div className="accountSettingBody4">
          <div className="accountSettingBody4Subbody">
            <div className="accountSettingBody3SubbodyHeading">
              Click to enjoy more offers and discounts
            </div>

            <div className="accountSettingBody3SubbodyText">
              After completing your profile and adding all family members, click
              on the button to mark your profile as complete.
            </div>
          </div>
          <div className="accountSettingBody4Subbody2">
            <a href="/account-add-family-member">
              <button className="btnAddFamilyAccountSetting">
                Add Family Members
              </button>
            </a>
          </div>
        </div>

        <div className="accountSettingBody3">
          <div className="accountSettingBody3Subbody">
            <div className="accountSettingBody3SubbodyHeading">
              Subscribe to our newsletters and marketing communications.
            </div>

            <div className="accountSettingBody3SubbodyText">
              We will send you the latest offers, best products and updated
              medical information to your email address.
            </div>

            <Switch className="accountSettingSwitch" color="primary" />
          </div>
          <div className="accountSettingBody3Subbody">
            <div className="accountSettingBody3SubbodyHeading">
              Get whatsapp notifications
            </div>

            <div className="accountSettingBody3SubbodyText">
              We will send you the latest notifications related to your orders
              and lab reports to your mobile number
            </div>
            <Switch className="accountSettingSwitch" color="primary" />
          </div>
        </div>
      </div>
    </>
  );
}
export default AccountSetting;
