import { all } from "redux-saga/effects";
import { watchMyPrescriptionSaga } from "./myPrescriptionSaga";
import { watchMyReportDiagnosticSaga } from "./myReportDiagnosticSaga";
import { watchMyReportSaga } from "./myReportSaga";


function* rootAccountSaga() {
    yield all([
        watchMyPrescriptionSaga(),
        watchMyReportDiagnosticSaga(),
        watchMyReportSaga()
    ])
}

export default rootAccountSaga;
